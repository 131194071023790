import { AxiosResponse } from "axios";
import api from "./api/api";
import { AuthDto } from "./dto/auth.dto";
import IResponse from "./dto/response.dto";
import { IUser } from "./dto/user.dto";
import API_URLS from "./api/urls";

export class AuthService {
    static login(email: string, password: string): Promise<AxiosResponse<AuthDto>> {
        return api.post<AuthDto>(API_URLS.login.url, { email, password });
    }

    static checkAuth(): Promise<AxiosResponse<IResponse<IUser>>> {
        return api.get(API_URLS.authCheck.url);
    }
}