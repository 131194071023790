import { action, makeObservable, observable } from "mobx";
import accountsService from "../services/accounts.service";
import { IAccount, ICreateAccountDto } from "../services/dto/account.dto";
import { GetUsersDto } from "../services/dto/user.dto";
import appStore, { ModalKey } from "./app.store";
import notifierStore from "./notifier.store";
import PaginationStore from "./pagination.store";
import SearchStore from "./search.store";

export class AccountsStore extends PaginationStore<IAccount> {
    selectedEntities: Array<number> = [];
    searchStore: SearchStore = new SearchStore("user_id");

    constructor() {
        super();
        makeObservable(this, {
            data: observable,
            selectedEntities: observable,
            page: observable,
            rowsPerPage: observable,
            count: observable,

            clearSelected: action,
            setCount: action,
            setList: action,
            handleClickRow: action,
            handleChangePage: action,
            handleChangeRowsPerPage: action
        });
    }

    fetch(getUsersDto: GetUsersDto) {
        const search = this.searchStore.getSearchObject();
        getUsersDto = { ...getUsersDto, ...search };
        
        accountsService.getEntities(getUsersDto).then(response => {
            this.setList(response.data.data.list);
            this.setCount(response.data.data.count);
        });
    }

    reloadPage(page: number = 0) {
        this.fetch({ 
            limit: this.rowsPerPage,
            page,
        });
    }

    handleLoadLoggerData = (event: any) => {
        const { id } = event.target.dataset;
        if (!id) {
            return notifierStore.errorSnackbar("Ошибка при получении данных.");
        }
        accountsService.getLoggerData(id).then(response => {
            appStore.showModal({ key: ModalKey.loggerAccount, data: response.data.data })
        });
    }
    
    handleLoadLocationsData = (event: any) => {
        const { id } = event.target.dataset;
        if (!id) {
            return notifierStore.errorSnackbar("Ошибка при получении данных.");
        }
        accountsService.getLocationsData(id).then(response => {
            appStore.showModal({ key: ModalKey.locationsAccount, data: response.data.data })
        });
    }
    handleEditClick = (e: any) => {
        if (!this.selectedEntities.length) throw new Error("Request not selected");
        const entity = this.data.filter(u => u.id === this.selectedEntities[0])[0];
        appStore.showModal({ key: ModalKey.createAccount, data: entity, type: 'transferData'});
    } 

    handleCreateOrUpdate = (event: any) => {
        const modalKey = ModalKey.createAccount;
        const formData = appStore.getModalData<ICreateAccountDto>(modalKey);
        if (!formData) notifierStore.errorSnackbar("Форма пуста!")
        const modalType = appStore.getModalType(modalKey);
        
        if (modalType === 'default') {
            accountsService.createEntity(formData).then(response => {
                this.reloadPage();
                appStore.hideModal(modalKey)
            });
        } else if (modalType === 'transferData') {
            const selectedId = this.selectedEntities[0];
            const { date, hlr, id, link_to_logger, ...rest } = formData as any;
            rest.user_id = rest.user_id.toString();

            accountsService.updateEntity(selectedId, rest).then(response => {
                this.reloadPage();
            });
        }

        this.searchStore.clear();
    }

    handleAgreeDelete = (event: any) => {
        accountsService.deleteEntities(this.selectedEntities).then(response => {
            this.reloadPage();
            this.clearSelected();
        });
    }

    clearSelected = () => {
        this.selectedEntities = [];
    }

    handleClickRow = (event: any, requestId: number) => {
        // если svg, значит иконка раскрытия/закрытия. Отмечать не нужно
        if (['path', 'svg'].includes(event.target.tagName)) return;
        
        if (!this.isEntitySelected(requestId))
           this.selectedEntities.push(requestId);
        else 
           this.selectedEntities = this.selectedEntities.filter(id => id !== requestId);
   }

    handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {        
        this.setPage(newPage)
        this.selectedEntities = [];
    }

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setRowsPerPage(parseInt(event.target.value, 10));
        this.setPage(0);
        this.selectedEntities = [];
    }

    isEntitySelected = (userId: number): boolean => this.selectedEntities.indexOf(userId) !== -1;
}

export default new AccountsStore();