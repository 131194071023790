class PaginationStore<T extends { id: number }> {
    private readonly PER_PAGE_KEY: string = "perPage";

    data: Array<T> = [];
    page: number = 0;
    rowsPerPage: number = 10;
    count: number = 10; // всего записей в базе
    
    constructor() {
        this.loadRowsPerPageFromLS();
    }

    loadRowsPerPageFromLS() {
        const rowsPerPage = this.getRowsPerPageFromLS();
        if (rowsPerPage !== null) 
            this.rowsPerPage= rowsPerPage;
    }

    setPage(page: number) {
        this.page = page;
    }

    setCount(count: number) {
        this.count = count;
    }

    setRowsPerPage(rows: number) {
        this.rowsPerPage = rows;
        localStorage.setItem(this.PER_PAGE_KEY, rows.toString());
    }
    setList(list: Array<T>) {
        this.data = list;
    }

    replaceEntity(entity: T) {
        this.data = this.data.map(e => e.id === entity.id ? entity : e);
    }

    getRowsPerPageFromLS(): number | null {
        const rows = localStorage.getItem(this.PER_PAGE_KEY);
        return rows !== null ? parseInt(rows) : rows
    }

}


export class SelectableTableRowsStore {
    selectedRows: Array<number> = [];
    isRowSelected = (userId: number): boolean => this.selectedRows.indexOf(userId) !== -1;

    handleClickRow = (event: React.MouseEvent<unknown>, userId: number) => {
        if (!this.isRowSelected(userId))
           this.selectedRows.push(userId);
        else 
           this.selectedRows = this.selectedRows.filter(id => id !== userId);
   }

  
}

export default PaginationStore;