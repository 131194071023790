import { ISearchableDto } from "./request.dto";

export interface IUser {
    id: number;
    email: string;
    name: string;
    type: Role,
    api_key: string
}

export enum Role {
    USER, ADMIN
}

export interface GetUsersDto extends ISearchableDto {
    limit: number;
    page: number;
}

export interface PaginateResponse<T> {
    count: number;
    list: T[]
}

export interface CreateUserDto {
    email: string;
    password: string;
    name: string;
    role: Role
}

export interface UpdateUserDto {
    email: string;
    name: string;
    type: Role
}