import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import appStore from '../../stores/app.store';
import { observer } from 'mobx-react-lite';
import { IDialogProps } from './types/types';
import AppDialog from './appDialog';

const AlertDialog: React.FC<IDialogProps> = ({ id, autoClose=true, waitData=false }) => {
  const options = appStore.modalState[id];

  if (!options) return null;
  const { text } = options;
  
  return (
      <AppDialog id={id} autoClose={autoClose} withDefaultActions={true} waitData={waitData}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
      </AppDialog>
  );
};


export default observer(AlertDialog);