import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { IBarProps } from "./types/types";

const Bar: React.FC<IBarProps> = ({ width = 1000, title = "", handleDrawerToggle, mobileOpen }) => {
    return (
        <AppBar position='fixed'
            sx={{
                 width:  {sm: `calc(100% - ${width}px)`},
                 ml: { sm: `${width}px` },
            }}
          >
            <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
                {title}
            </Typography>
            </Toolbar>
          </AppBar>
    )
}

export default Bar;