import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import {
    Link as RouterLink,
  } from 'react-router-dom';
import { IListItemLinkProps } from "./types/types";


const ListItemLink: React.FC<IListItemLinkProps> = (props) => {
    const { icon, text, to } = props;
  
    const renderLink = React.useMemo(
      () =>
        React.forwardRef(function Link(itemProps, ref) {
          return <RouterLink to={to}  {...itemProps} role={undefined} />;
        }),
      [to],
    );
  
    return (
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={text} />
        </ListItem>
    );
  }


export default ListItemLink;