import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import accountsStore from "../../stores/accounts.store";
import requestsStore from "../../stores/requests.store";
import AppBackdrop from "../backdrop";
import PrivateRoute from "./privateRoute";

const AccountsPage = React.lazy(() => import("../../pages/accounts.page"))
const RequestsPage = React.lazy(() => import("../../pages/requests.page"))
const UsersPage = React.lazy(() => import("../../pages/users.page"))
const SignInPage = React.lazy(() => import("../../pages/signIn.page"))

const AppRouter = () => {
    return (
        <Suspense fallback={false}>
            <Routes>
                <Route path='/' element={ <PrivateRoute component={<AccountsPage store={accountsStore} />} /> } />
                <Route path='/requests' element={ <PrivateRoute component={<RequestsPage store={requestsStore} />} /> } />
                <Route path='/users' element={ <PrivateRoute component={<UsersPage />} /> } />
                <Route path='/login' element={ <SignInPage /> } />
            </Routes>
        </Suspense>
    )
}

export default AppRouter;