import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import appStore from '../../stores/app.store';
import { observer } from 'mobx-react-lite';
import { IDialogProps } from './types/types';

const AppDialog: React.FC<IDialogProps> = ({ id, autoClose=true, children, withDefaultActions, waitData, directInjection, ...rest }) => {
  const options = appStore.modalState[id];

  if (!options) return null;
  const { isOpen, handleAgreeClick, handleDisagreeClick, title, data } = options;

  const onClose = (e: any) => {
      if (handleDisagreeClick)
        handleDisagreeClick(e);

      appStore.hideModal(id);
  }

  const onAgree = (e: any) => {
      if (handleAgreeClick)
        handleAgreeClick(e);

      if (autoClose)
        appStore.hideModal(id);
    }
  

  return waitData && !data ? null : (
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...rest}
      >
        <DialogTitle id="alert-dialog-title">
          { directInjection && directInjection.title ? directInjection.title : title }
        </DialogTitle>
        {children}
        {
            withDefaultActions &&
            (
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={onAgree} autoFocus>
                    Подтвердить
                </Button>
            </DialogActions>
            )
        }
      </Dialog>
  );
};


export default observer(AppDialog);