import { Drawer, Toolbar, Divider, ListItem, ListItemText, ListItemIcon, List, Box, ListSubheader, Typography } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListItemLink from './router/listItemLink';
import LockListItem from './lockListItem';
import { IAppDrawerProps, IConfigUrl } from './types/types';
import authStore from '../stores/auth.store';
import { ROUTES_CONFIG } from './../consts/router';
import { bots } from '../consts/bots';

export const drawerWidth = 230;

export const AppDrawer: React.FC<IAppDrawerProps> = ({ isAuth, handleDrawerToggle, mobileOpen, headerText }) => {
    const config = ROUTES_CONFIG
    const drawerContent = (

        isAuth ? 
        <div>
            <Toolbar>
              { headerText}
            </Toolbar>

            <Divider />
            <List subheader={<ListSubheader>Страницы</ListSubheader>}>
                {config.map((el: IConfigUrl) => {
                    if (!el.role || authStore.user?.type === el.role) {
                      const IconComponent = el.icon;
                      return <ListItemLink key={el.name} to={el.url} text={el.name} icon={<IconComponent/>} />
                    }
                    return null
                  }
                )}
            </List>
            <Divider />
            <List subheader={<ListSubheader>Настройки</ListSubheader>}>
                <ListItem button onClick={() => authStore.logout()}>
                    <ListItemIcon>
                        <ExitToAppIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Выход"/>
                </ListItem>                
            </List>

            <Divider />
            <List subheader={<ListSubheader>Текущий список ботов</ListSubheader>}>
                  {bots.map((name, i) => <ListItem key={i} sx={{ paddingBottom: 0, paddingTop: 0 }}> <Typography variant='caption' >{name}</Typography> </ListItem>)}
            </List>
        </div>
        : (
            <>
                <LockListItem text="Необходимо войти в аккаунт" />
                <Divider/>
            </>
        )
    );

    return (

        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawerContent}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawerContent}
        </Drawer>
      </Box>
        
    )

}
