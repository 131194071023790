import { IConfigUrl } from "../components/types/types";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Role } from "../services/dto/user.dto";

export const ROUTES_CONFIG: Array<IConfigUrl> = [
    { url: '/', name: "Аккаунты", icon: PersonOutlineIcon },
    { url: '/requests', name: "Запросы", icon: FingerprintIcon },
    { url: '/users', name: "Пользователи", icon: AccountCircleIcon, role: Role.ADMIN },
]