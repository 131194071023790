export interface IApiUrlConfig {
    [key: string] : { [key: string] : string }
}

const API_URLS: IApiUrlConfig  = {
    login: { url: "/auth/login" },
    authCheck: { url: '/auth/check' },
    users: {
        get: "/users",
        delete: "/users",
        create: "/users",
        getOne: '/users',
        update: '/users',
    },
    requests: {
        get: '/requests',
        create: '/requests',
        getOne: '/requests',
        delete: '/requests',
        update: '/requests',
    },
    accounts: {
        main: '/accounts',
        getLoggerData: '/accounts/logger',
        getLocationsData: '/accounts/locations'
    }
}


export default API_URLS;


