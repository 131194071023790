import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

ReactDOM.render(
  
    <HashRouter>
      <CssBaseline/>
      <App />
    </HashRouter>
  
    ,
  document.getElementById('root')
);
