import { action, makeObservable, observable } from "mobx";
import { OptionsObject, SnackbarMessage } from "notistack";

class NotifierStore {
    // null = необходим запрос аутентификации, false = не аутентифицирован
    notifications: Array<{ key: number, message: SnackbarMessage, options: OptionsObject }> = []
    
    constructor() {
        makeObservable(this, {
            notifications: observable,
            enqueueSnackbar: action,
            removeSnackbar: action
        });    
    }

    successSnackbar = ( message: SnackbarMessage) => {
        this.enqueueSnackbar({ message, options:  { variant: 'success'} })
    }

    errorSnackbar = ( message: SnackbarMessage) => {
        this.enqueueSnackbar({ message, options:  { variant: 'error'} })
    }

    infoSnackbar = ( message: SnackbarMessage) => {
        this.enqueueSnackbar({ message, options:  { variant: 'info'} })
    }

    enqueueSnackbar = (note: { message: SnackbarMessage, options: OptionsObject}) => {
        this.notifications.push({
            key: this.generateKey(),
            ...note,
        });

    }

    removeSnackbar = (key: number) => {
        this.notifications = this.notifications.filter(notification => notification.key !== key);
    }

    generateKey = () => {
        return new Date().getTime() + Math.random();
    }
}
export default new NotifierStore();