import { action, makeObservable, observable } from "mobx";
import { ICreateRequestDto, IRequest } from "../services/dto/request.dto";
import { GetUsersDto } from "../services/dto/user.dto";
import { RequestsService } from "../services/requests.service";
import appStore, { ModalKey } from "./app.store";
import notifierStore from "./notifier.store";
import PaginationStore from "./pagination.store";
import SearchStore from "./search.store";


export class RequestsStore extends PaginationStore<IRequest> {
    selectedRequests: Array<number> = [];
    searchStore: SearchStore = new SearchStore("user_id");

    constructor() {
        super();
        makeObservable(this, {
            data: observable,
            selectedRequests: observable,
            page: observable,
            rowsPerPage: observable,
            count: observable,

            clearSelected: action,
            setCount: action,
            setList: action,
            handleClickRow: action,
            handleChangePage: action,
            handleChangeRowsPerPage: action
        });
    }

    fetchRequests(getUsersDto: GetUsersDto) {
        const search = this.searchStore.getSearchObject();
        getUsersDto = { ...getUsersDto, ...search };
        
        RequestsService.getRequests(getUsersDto).then(response => {
            this.setList(response.data.data.list);
            this.setCount(response.data.data.count);
        }).catch(err => {
            console.error(err);
        });
    }

    reloadPage(page: number = 0) {
        this.fetchRequests({ 
            limit: this.rowsPerPage,
            page,
        });
    }
    
    handleEditClick = (e: any) => {
        if (!this.selectedRequests.length) throw new Error("Request not selected");
        const request = this.data.filter(u => u.id === this.selectedRequests[0])[0];
        appStore.showModal({ key: ModalKey.createRequest, data: request, type: 'transferData'});
    } 

    handleCreateOrUpdateRequest = (event: any) => {
        const formData = appStore.getModalData<ICreateRequestDto>(ModalKey.createRequest);
        if (!formData) notifierStore.errorSnackbar("Форма пуста!")
        const modalType = appStore.getModalType(ModalKey.createRequest);
        
        if (modalType === 'default') {
            RequestsService.createRequest(formData).then(response => {
                this.reloadPage();
                appStore.hideModal(ModalKey.createRequest);
            });
        } else if (modalType === 'transferData') {
            RequestsService.updateRequest(this.selectedRequests[0], formData).then(response => {
                this.reloadPage();
                appStore.hideModal(ModalKey.createRequest);
            });
        }
    }

    handleAgreeDeleteRequests = (event: any) => {
        RequestsService.deleteRequests(this.selectedRequests).then(response => {
            this.reloadPage();
            this.clearSelected();
        });
    }

    clearSelected = () => {
        this.selectedRequests = [];
    }

    handleClickRow = (event: React.MouseEvent<unknown>, requestId: number) => {
        if (!this.isRequestSelected(requestId))
           this.selectedRequests.push(requestId);
        else 
           this.selectedRequests = this.selectedRequests.filter(id => id !== requestId);
   }

    handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {        
        this.setPage(newPage)
        this.selectedRequests = [];
    }

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setRowsPerPage(parseInt(event.target.value, 10));
        this.setPage(0);
        this.selectedRequests = [];
    }

    isRequestSelected = (userId: number): boolean => this.selectedRequests.indexOf(userId) !== -1;


}

export default new RequestsStore();