import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import notifierStore from '../stores/notifier.store';


const Notifier: React.FC = () => {
    let displayed: Array<number> = [];
    const { enqueueSnackbar } = useSnackbar();

    const storeDisplayed = (id: number) => {
        displayed = [...displayed, id];
    };

    useEffect(() => {
        autorun(() => {
            
            const { notifications = [] } = notifierStore;
            
            notifications.forEach((notification: any) => {
                // Do nothing if snackbar is already displayed
                if (displayed.includes(notification.key)) return;
                // Display snackbar using notistack
                enqueueSnackbar(notification.message, notification.options);
                // Keep track of snackbars that we've displayed
                storeDisplayed(notification.key);
                // Dispatch action to remove snackbar from mobx store
                notifierStore.removeSnackbar(notification.key);
            });
        });
    }, []);

    return null;
}

export default observer(Notifier)