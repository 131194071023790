export const bots = [
    "@inform_baza_bot",
    "@dossier_baza_bot",
    "@addprivategroup_bot",
    "@protestchat_bot",
    "@TgDeanonymizer_bot",
    "@TelpoiskBot_bot",
    "@cryptoscanning_bot",
    "@Checknumb_bot",
    "@LBSE_bot",
    "@GetCont_bot",
    "@TgAnalyst_bot"
];