import { Backdrop, CircularProgress } from "@mui/material";

const AppBackdrop: React.FC<{ show: boolean }>= ({ show }) => {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 15 }} open={show}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
}


export default AppBackdrop;