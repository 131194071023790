import { observer } from 'mobx-react-lite';
import { SnackbarProvider } from 'notistack';
import {  useEffect } from 'react';
import './App.css';
import AppContainer from './components/appContainer';
import AppBackdrop from './components/backdrop';
import AlertDialog from './components/modal/alertDialog';
import Notifier from './components/notifier';
import appStore, { ModalKey } from './stores/app.store';
import authStore from './stores/auth.store';


const App: React.FC = () => {
  useEffect(() => authStore.checkAuth(), []);
  return (
    authStore.isAuth === null ? 
      <AppBackdrop show={true} />
    :
    (
      <SnackbarProvider maxSnack={5} >
        <Notifier/>
        <AlertDialog id={ModalKey.confirm} autoClose={true} withDefaultActions={true} waitData={false} />
        <AppContainer withAppBar={true}></AppContainer>
      </SnackbarProvider>
    )
  );
}

export default observer(App);
