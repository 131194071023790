import './../App.css';
import {AppDrawer, drawerWidth} from './drawer';
import { Box, Chip, Toolbar } from "@mui/material";
import Bar from './bar';
import { useState } from 'react';
import AppRouter from './router/appRouter.router';
import { IAppContainerProps } from './types/types';
import { observer } from 'mobx-react-lite';
import authStore from '../stores/auth.store';
import AppBackdrop from './backdrop';
import appStore from '../stores/app.store';

const AppContainer: React.FC<IAppContainerProps> = ({ withAppBar = true, children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const header = (
      <Chip label={authStore.isAdmin() ? "Администратор":"Пользователь"} color="primary" variant="outlined" />
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBackdrop show={appStore.loaderState} />
          { withAppBar && <Bar width={drawerWidth} title={"Панель управления"} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} /> }
          <AppDrawer isAuth={authStore.isAuth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} headerText={header} />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 2, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <AppRouter/>
          { children }
      </Box>
    </Box>
  );
}


export default observer(AppContainer);
