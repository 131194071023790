import { AxiosResponse } from "axios";
import api from "./api/api";
import IResponse from "./dto/response.dto";
import { GetUsersDto, PaginateResponse } from "./dto/user.dto";
import API_URLS from "./api/urls";
import { ICreateRequestDto, IRequest, IUpdateRequestDto } from "./dto/request.dto";

export class RequestsService {
    static getRequests(getUsersDto: GetUsersDto): Promise<AxiosResponse<IResponse<PaginateResponse<IRequest>>>> {
        return api.get(API_URLS.requests.get, {params: getUsersDto});
    }

    static deleteRequests(requestsId: Array<number>): Promise<AxiosResponse<IResponse<boolean>>> {
        return api.delete(API_URLS.requests.delete, { params: { id: requestsId } });
    }

    static createRequest(createRequestDto: ICreateRequestDto): Promise<AxiosResponse<IResponse<boolean>>> {
        return api.post(API_URLS.requests.create, createRequestDto);
    }

    static getRequest(id: number): Promise<AxiosResponse<IResponse<IRequest>>> {
        return api.get(API_URLS.requests.getOne + `/${id}`);
    }

    static updateRequest(id: number, updateRequestDto: IUpdateRequestDto): Promise<AxiosResponse<IResponse<boolean>>> {
        return api.put(API_URLS.requests.update + `/${id}`, updateRequestDto);
    }
    
}