import { makeAutoObservable } from "mobx";
import { AuthService } from "../services/auth.service";
import { LoginDataDto } from "../services/dto/auth.dto";
import { IUser, Role } from "../services/dto/user.dto";
import notifierStore from "./notifier.store";

class AuthStore {
    // null = необходим запрос аутентификации, false = не аутентифицирован
    isAuth: boolean | null = null;
    user: IUser | null = null;
    
    constructor() {
        makeAutoObservable(this);
    }

    public isAdmin(): boolean {
        return this.user !== null && this.user.type === Role.ADMIN;
    }

    public setIsAuth(state: boolean): void {
        this.isAuth = state;
    }

    public setUser(user: IUser): void {
        this.user = user;
    }

    public async login(data: LoginDataDto) {
        const { email, password } = data;
        AuthService.login(email, password).then((r) => {
            this.setIsAuth(true);
            this.setUser(r.data.user);
            localStorage.setItem('access_token', r.data.access_token);
            notifierStore.successSnackbar("Успешный вход.")
        }).catch(err => {
            this.setIsAuth(false);
        })
    }

    checkAuth = () => {
        const token = localStorage.getItem('access_token');
        if (!token) return this.setIsAuth(false);
        
        AuthService.checkAuth().then(r => {
            this.setIsAuth(true);
            this.setUser(r.data.data);
        }).catch(() => {
            this.setIsAuth(false);
            notifierStore.infoSnackbar(token ? "Ключ доступа больше не активен. Перезайдите в аккаунт." : "Необходимо войти в аккаунт.")
        });
    }

    logout = () => {
        this.setIsAuth(false);
        localStorage.removeItem('access_token');
    }

}
// const authStoreContext = createContext(new AuthStore());
// export const useAuthStore = () => useContext(authStoreContext);
export default new AuthStore();