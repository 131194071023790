import axios from "axios";
import appStore from "../../stores/app.store";
import notifierStore from "../../stores/notifier.store";
import { errorMessages, successMessages } from "./messages";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: `${BASE_URL}/`,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  appStore.showLoader();
  const token = localStorage.getItem("access_token");

  if (config.headers && token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

api.interceptors.response.use(
  function (response) {
    appStore.hideLoader();

    const message = response.data.message || successMessages[response.status];
    if (message) notifierStore.successSnackbar(message);

    return response;
  },
  function (error) {
    appStore.hideLoader();

    const { message } = error.response.data;
    if (error.response.data.message) {
      notifierStore.errorSnackbar(
        Array.isArray(message) ? message.join("\n") : message
      ); // validator errors
    } else {
      const message = errorMessages[error.response.status];
      if (message) {
        notifierStore.errorSnackbar(message);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
