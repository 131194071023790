import { AxiosResponse } from "axios";
import api from "./api/api";
import IResponse from "./dto/response.dto";
import { GetUsersDto, PaginateResponse } from "./dto/user.dto";
import API_URLS from "./api/urls";
import { IAccount, ICreateAccountDto, IUpdateAccountDto } from "./dto/account.dto";
import { Service } from "./service";
import { Location } from "./dto/location.dto";


class AccountsService implements Service {
    deleteEntities(ids: number[]): Promise<AxiosResponse<IResponse<boolean>>> {
        return api.delete(API_URLS.accounts.main, { params: { id: ids } });
    }
    createEntity(dto: ICreateAccountDto): Promise<AxiosResponse<IResponse<boolean>>> {
        return api.post(API_URLS.accounts.main, dto);
    }
    getEntity(id: number): Promise<AxiosResponse<IResponse<IAccount>>> {
        return api.get(API_URLS.accounts.main + `/${id}`);
    }
    updateEntity(id: number, dto: IUpdateAccountDto): Promise<AxiosResponse<IResponse<boolean>>> {
        return api.put(API_URLS.accounts.main + `/${id}`, dto);
    }
    getEntities(dto: GetUsersDto): Promise<AxiosResponse<IResponse<PaginateResponse<IAccount>>>> {
        return api.get(API_URLS.accounts.main, {params: dto});
    }

    getLoggerData(id: number): Promise<AxiosResponse<IResponse<string>>> {
        return api.get(API_URLS.accounts.getLoggerData + `/${id}`);
    }

    getLocationsData(id: number): Promise<AxiosResponse<IResponse<Array<Location>>>> {
        return api.get(API_URLS.accounts.getLocationsData + `/${id}`);
    }

}

export default new AccountsService();