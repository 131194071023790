import LockIcon from '@mui/icons-material/Lock';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ILockListItemProps } from './types/types';

const LockListItem: React.FC<ILockListItemProps> = ({ text = '' }) => {
    return (
        <ListItem>
            <ListItemIcon>
                <LockIcon/>
            </ListItemIcon>
            <ListItemText primary={text} />
        </ListItem>
    )
}

export default LockListItem;