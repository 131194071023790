import { makeAutoObservable } from "mobx";
import { ChangeEvent } from "react";
import { ISearchableDto } from "../services/dto/request.dto";

class SearchStore {
    key: string | null = "";
    value: string | null = "";
    
    constructor(defaultKey: string) {
        this.key = defaultKey;
        makeAutoObservable(this);
    }

    onChangeType = (e: ChangeEvent<HTMLInputElement>) => {
        this.key = e.target.value;
    }

    onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        this.value = e.target.value;
    }

    clear = () => {
        this.value = "";
    }

    getSearchObject(): ISearchableDto {
        return  this.value ? { searchValue: this.value.toString(), searchBy: this.key?.toString() } : {}
    }

}
export default SearchStore;