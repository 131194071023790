import { action, makeObservable, observable } from "mobx";
import React from "react";

export enum ModalKey {
    confirm,
    createUser,
    createRequest,
    createAccount,
    editUser,
    loggerAccount,
    locationsAccount
}

/**
 * default - обычное открытие без лишних манипуляций
 * transferData - говорит о том, что к модальному окну прикреплены данные 
 *                и их необходимо обработать
 * common - может выполнять и обычное открытие, так и получение данные
 */
export type ModalType = 'default' | 'transferData' | 'common';
 
interface IModalStateContainerOptions {
    id: ModalKey // ключ диалога для стора
    text?: string;
    title?: string;
    data?: any;
    isOpen: boolean;
    isInit?: boolean; 
    type: ModalType;
    handleAgreeClick?: React.MouseEventHandler,
    handleDisagreeClick?: React.MouseEventHandler
}

interface IModalStateContainer {
    [key: ModalKey | number]: IModalStateContainerOptions
}

class AppStore {
    
    modalState: IModalStateContainer = { };
    loaderState: boolean = false;
    
    constructor() {
        makeObservable(this, {
            modalState: observable,
            loaderState: observable,
            initModal: action,
            changeModalState: action,
            setModalType: action,
            setModalData: action,
            showModal: action,
            hideModal: action,
            setLoaderState: action
        });
    }
    setLoaderState = (state: boolean) => {
        this.loaderState = state;
    }

    showLoader = () => this.setLoaderState(true);
    hideLoader = () => this.setLoaderState(false);

    initModal(options: IModalStateContainerOptions) {
        this.modalState[options.id] = {isInit: true,  ...options};
    }

    showModal = ({key, data, type}: { key: ModalKey, data?: any, type?: ModalType }) => {
        if (data) 
            this.setModalData(key, data);
        if (type)
            this.setModalType(key, type);
        
        this.changeModalState(key, true);
    }

    modalHasData(key: ModalKey): boolean {
        return this.modalState[key].data !== undefined && this.modalState[key].data !== null;
    }

    modalIsInit(key: ModalKey): boolean {
        return this.modalState[key] && this.modalState[key].isInit === true;
    }

    hideModal = (key: ModalKey) => {
        this.changeModalState(key, false);
        this.setModalType(key, 'default');
        if (this.modalState[key] !== null) {
            this.modalState[key]!.data = null;
        }
    }
    getModalType(key: ModalKey): ModalType {
        return this.modalState[key].type;
    }

    changeModalState = (key: ModalKey, state: boolean) => {
        if (this.modalState[key].isInit) {
            this.modalState[key]!.isOpen = state;
        } else {
            throw new Error(`Modal with id: ${key} is not initialized`);
        }
    }

    setModalType = (key: ModalKey, type: ModalType) => {
        if (this.modalState[key].isInit) {
            this.modalState[key].type = type;
        } else {
            throw new Error(`Modal with id: ${key} is not initialized`);
        } 
    }
    setModalData = <T>(key: ModalKey, data: T) => {
        if (this.modalState[key].isInit) {
            this.modalState[key]!.data = data;
        } else {
            throw new Error(`Modal with id: ${key} is not initialized`);
        }
    }

    getModalData = <T>(key: ModalKey): T => {
        if (this.modalIsInit(key) && this.modalHasData(key)) {
            return this.modalState[key].data as T;
        }

        return {  } as T;
    }



}

export default new AppStore();